<template>
	<div>
		<v-layout class="pa-2 border-bottom-light-grey min-height-40px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
					<!--end::Svg Icon-->
				</span>
				Stage History
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 345px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg">Stage</th>
						<th class="p-2 light-blue-bg">Stage Duration</th>
						<th class="p-2 light-blue-bg">Modified By</th>
					</tr>
				</thead>
				<tbody v-if="stageHistoryData?.length">
					<tr v-for="(row, index) in stageHistoryData" :key="index">
						<td class="p-2 border-top-light-grey">
							<div class="text-capitalize">{{ row.name }}</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="" v-if="row.quotationstatus !='0 Sec'">{{ row.quotationstatus }}</div>
							<div class="" v-else>{{ getTimeDifference(row) }}</div>

						</td>
						<td class="p-2 border-top-light-grey">
							<div class=""><v-icon small>mdi-calendar</v-icon>{{ row.date }}</div>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="6">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no stage history at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
// import { toNumber, round, last } from "lodash";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		url: {
			type: String,
			default: null,
		},
		typeId: {
			type: Number,
			default: 0,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		stageHistoryData: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			pageLoading: true,
			// stageHistoryData: [
			// 	{
			// 		id: 1,
			// 		stage: "Needs Analysis",
			// 		stageDuration: "Current Stage",
			// 		modified_by: "06/06/2024 02:19 PM",
			// 	},
			// 	{
			// 		id: 2,
			// 		stage: "Qualification",
			// 		stageDuration: "1",
			// 		modified_by: "05/06/2024 04:19 PM",
			// 	},
			// ],
		};
	},
	methods: {
		getTimeDifference(row) {
      const date1 = new Date(row.date);
      const date2 = new Date(); 
      const diffInSeconds = Math.floor((date2 - date1) / 1000); // Difference in seconds
      const diffInMinutes = diffInSeconds / 60;
      
      const hoursInOneHour = 60;
      const minutesInOneDay = 24 * hoursInOneHour;

      if (diffInMinutes < 1) {
        return `${diffInSeconds} Sec`;
      } else if (diffInMinutes < hoursInOneHour) {
        return `${Math.floor(diffInMinutes)} Min`;
      } else if (diffInMinutes < minutesInOneDay) {
        const hours = diffInMinutes / hoursInOneHour;
        return `${Math.floor(hours)} Hour`;
      } else {
        const days = diffInMinutes / minutesInOneDay;
        return `${Math.floor(days)} Days`;
      }
    }
		
	},
	components: {},
	mounted() {},
};
</script>
<style scoped>
.detail-table.table-head-sticky tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
.detail-table.table-head-sticky tbody tr:hover {
	background-color: #eeeeee;
}
</style>
